import React, {  useCallback, useContext, useEffect, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridPageChangeEvent,
    GridDataStateChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { useOktaAuth } from "@okta/okta-react";
import "@progress/kendo-theme-default/dist/all.css";
import "../../northwind.css"
import "../../custom.css"
import DOAIcon from "../../assets/DOA Icon.svg";
import {
    State,
    process,
    DataResult,
} from "@progress/kendo-data-query";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { IDelegation } from "../../Interfaces/IDelegation";
import { userContext } from '../../components/Context/UserContext'; 
import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
  } from "@progress/kendo-react-layout";
  import { ColumnMenu } from "../FilterMenu/columnMenu";
import { CAP_URL } from "../../utils/constants";


  interface PageState {
    skip: number;
    take: number;
  }
  
  const initialDataState: PageState = { skip: 0, take: 10 };



const Home = () => {
    //static displayName = Home.name;
    const history = useHistory();
    const user = useContext(userContext);    
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [table1, setTable1] = useState([]);
    const [table2, setTable2] = useState([]);
    const [selected, setSelected] = React.useState<number>(0);
    const { oktaAuth } = useOktaAuth();


    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
      };

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
        setPage(initialDataState);
      };

    const onRowClicked = (e: GridRowClickEvent) => {
        if (e.dataItem.status.toLowerCase() === 'draft') {
            history.push("/Edit/" + e.dataItem.id);
        }
        else {
            history.push("/Details/" + e.dataItem.id); 
        }
    }

    //Grid render
    const cellWithBadge = (props: GridCellProps) => {
        let badgeStyle = "";

        if (props.dataItem.status === "Draft"){
            badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-bright-turquoise fs-6';
        } 
        if (props.dataItem.status === "Revoked"){
            badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-pale-sky fs-6';
        }
        if(props.dataItem.status === "Active"){
            badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-cerulean fs-6';
        }
        if(props.dataItem.status === "Deleted"){
            badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-danger fs-6';
        }
        if (props.dataItem.status == "Expired") {
            badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-warning fs-6';
        }
     
        
        return (
          <td className="d-flex justify-content-center align-self-center ">
            <span className={badgeStyle}>{props.dataItem.status}</span>
            
          </td>
        );
      };
    //


 // FilterTo
    const createDataStateTo = (dataState: State) => {
        return {
            result: process(table1, dataState),
            dataState: dataState,
          }
    };
    
      let initialStateTo = createDataStateTo({
        take: 10,
        skip: 0,
      });

      const [resultTo, setResultTo] = React.useState<DataResult>(initialStateTo.result);
      const [dataStateTo, setDataStateTo] = React.useState<State>(initialStateTo.dataState);
      const [lengthTo, setLengthTo] = useState();

      const dataStateChangeTo = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataStateTo(event.dataState);
        setResultTo(updatedState.result);
        setDataStateTo(updatedState.dataState);
        setLengthTo(updatedState.result.length);
      };

        const startDateRender = ({dataItem}: GridCellProps) => {
        let startDate: Date = new Date(dataItem.startDate).toLocaleString().split(',')[0] 
        return (

            <td>{startDate}</td>
        )
      };

      const expiryDateRender = ({dataItem}: GridCellProps) => {
        let expiryDate: Date = new Date(dataItem.expiryDate).toLocaleString().split(',')[0]
        
        return (

            <td>{expiryDate}</td>
        )
      };

      //

       // FilterFrom
    const createDataStateFrom = (dataState: State) => {
        return {
            result: process(table2, dataState),
            dataState: dataState,
          }
    };
    
      let initialStateFrom = createDataStateFrom({
        take: 10,
        skip: 0,
      });

      const [resultFrom, setResultFrom] = React.useState<DataResult>(initialStateFrom.result);
      const [dataStateFrom, setDataStateFrom] = React.useState<State>(initialStateFrom.dataState);
      const [lengthFrom, setLengthFrom] = useState();

      const dataStateChangeFrom = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataStateFrom(event.dataState);
        setResultFrom(updatedState.result);
        setDataStateFrom(updatedState.dataState);
        setLengthFrom(updatedState.result.length);
      };

      //

    const getDelegationsToUser = useCallback(async () => {
        const formurl = process.env.REACT_APP_ENDPOINT_URL + `/api/Delegations/To/${user.bamboohrId}`;
        const accessToken = await oktaAuth.getAccessToken();
        await axios.get<Response>(formurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((result: any) => {
                var loaded: IDelegation[] = result.data;
                setTable1(loaded);
                setResultTo(loaded.slice(0, 10));
                setLengthTo(loaded.length);
                
            })
    }, []);

    const getDetlationsFromUser = useCallback(async () => {
        const formurl = process.env.REACT_APP_ENDPOINT_URL + `/api/Delegations/From/${user.bamboohrId}`;
        const accessToken = await oktaAuth.getAccessToken();
        await axios.get(formurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((result:any) => {
                var loaded: IDelegation[] = result.data; 
                setTable2(loaded);
                setResultFrom(loaded.slice(0, 10));
                setLengthFrom(loaded.length);
            })
    }, []);

    useEffect(() => {
        getDelegationsToUser();
        getDetlationsFromUser();
    }, [])

    return (
        <div className="pb-4">
            <div className="text-center">
                <p>
                    To review the Delegation of Authority policy, click here: &nbsp;
                    <a target="_blank" className="text-prussian-blue" href={CAP_URL}>
                         CAP-7011 in the Document Library
                    </a>.
                </p>
                <div className="py-3"> 
                    <Link to="Create">
                        <img src={DOAIcon} alt="Delegation of Authority Icon"  style={{maxWidth: "100px", minWidth: "50px"}} />
                        </Link>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="Create" className="createNewText">Create New</Link>
                </div>
            </div>
            <TabStrip
                selected={selected}
                onSelect={handleSelect}
                tabPosition={"top"}
            >
                <TabStripTab title="Delegations Created">
                    <div>
    
                        {table1 ?
                            <Grid
                                data={ resultTo}
                                {...dataStateTo }
                                onDataStateChange={ dataStateChangeTo }
                                pageable={true}
                                sortable={true}
                                total={lengthTo}
                                onRowClick={(dataItem) => onRowClicked(dataItem)}
                            >
                                <GridColumn field="id" title="Delegation No." columnMenu={ColumnMenu} />
                                <GridColumn field="startDate" title="Start Date" columnMenu={ColumnMenu} cell={startDateRender} />
                                <GridColumn field="expiryDate" title="End Date"  columnMenu={ColumnMenu} cell={expiryDateRender} />
                                <GridColumn field="designee" title="Designee" columnMenu={ColumnMenu} />
                                <GridColumn field="status" title="Status" cell={cellWithBadge} columnMenu={ColumnMenu} />
                                <GridColumn field="comments" title="Comments" columnMenu={ColumnMenu} />
                            </Grid>
                            :
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    </div>
                </TabStripTab>

                <TabStripTab title="Assigned Delegations">                
                
                    <div>
                        {table2 ?
                            <Grid
                                data={ resultFrom}
                                {...dataStateFrom }
                                onDataStateChange={ dataStateChangeFrom }
                                pageable={true}
                                sortable={true}
                                total={lengthFrom}
                                onRowClick={(dataItem) => onRowClicked(dataItem)}
                            >
                                <GridColumn field="id" title="Delegation No." columnMenu={ColumnMenu} />
                                <GridColumn field="startDate" title="Start Date" columnMenu={ColumnMenu} cell={startDateRender} />
                                <GridColumn field="expiryDate" title="End Date" columnMenu={ColumnMenu} cell={expiryDateRender} />
                                <GridColumn field="designator" title="Designator" columnMenu={ColumnMenu} />
                                <GridColumn field="status" title="Status" cell={cellWithBadge} columnMenu={ColumnMenu} />
                                <GridColumn field="comments" title="Comments" columnMenu={ColumnMenu} />
                            </Grid>
                            :
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    </div>
                </TabStripTab>


            </ TabStrip>
        </div>
    );
};
export default Home;
