import React, { Component, useStat, useEffect, useState, createContext } from 'react';
import { Layout } from './components/Layout';
import Home from './components/Pages/Home';
import Create from './components/UserForm/Create';
import Edit from './components/UserForm/Edit';
import Details from './components/UserForm/Details';
import Admin from './components/Pages/Admin';
import AdminDelegationForm from './components/Admin/AdminDelegationForm';
import UserContext from './components/Context/UserContext';

import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl, useOktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

import config from "./oktaConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-bootstrap/dist/all.scss";
import "bootstrap/scss/bootstrap.scss";
 
const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
     
    return (
        <>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Switch>
                    <Route path="/login/callback" component={LoginCallback} /> 
                    <UserContext>
                        <Layout> 
                            <SecureRoute path="/" exact={true} component={Home} />
                            <SecureRoute path='/Create' component={Create} />
                            <SecureRoute path='/Edit/:id' component={Edit} />

                            <SecureRoute path='/Details/:id' component={Details} />
                            <SecureRoute path='/Admin' component={Admin} />
                            <SecureRoute path='/DelegationRequestFormAdmin/:id' component={AdminDelegationForm} />
                        </Layout> 
                    </UserContext>
                </Switch>
            </Security>
        </>
    );
}

export default App