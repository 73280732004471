import React, { Component, useContext, useEffect, useState } from "react";
import {
  Collapse,
  Container,
  Label,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/DOA Logo 1.svg";
import "./NavMenu.css";
import { userContext } from "./Context/UserContext";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

const NavMenu = () => {
  const { oktaAuth } = useOktaAuth();
  const user = useContext(userContext);
  const [toggleNavbar, setToggleNavBar] = useState<Boolean>(true);
  const [isAdmin, setIsAdmin] = useState<any>(false);

  const getAdmin = async (id) => {
    const accessToken = await oktaAuth.getAccessToken();
    const formurl =
      process.env.REACT_APP_ENDPOINT_URL + `/api/Forms/isAdmin/${id}`;
    return await axios
      .get<Response>(formurl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => setIsAdmin(result.data));
  };

  useEffect(() => {
    if (user != null) {
      getAdmin(user.bamboohrId);
      console.log(isAdmin);
    }
  }, []);

  const onToggle = () => {
    setToggleNavBar(!toggleNavbar);
  };
  return (
    <header>
      {user !== null && (
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          <Container fluid>
            <NavbarBrand tag={Link} to="/" style={{ maxWidth: "650px" }}>
              <img
                src={Logo}
                className="d-inline-block align-top img-fluid"
                alt="North Wind Logo"
              />
            </NavbarBrand>
            <NavbarToggler onClick={onToggle} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!toggleNavbar}
              navbar
            >
              <span className="navbar-text ">Hello, {user.name}</span>
              {isAdmin === true && (
                <ul className="navbar-nav flex-grow">
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/Admin">
                      Admin
                    </NavLink>
                  </NavItem>
                </ul>
              )}
            </Collapse>
          </Container>
        </Navbar>
      )}
    </header>
  );
};
export default NavMenu;
