import React, { useEffect, useState, useCallback, useContext } from "react";
import { Col, Form, Row, Stack, Modal, Button } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useParams, useHistory } from "react-router-dom";
import { IDelegationRequestForm } from "../../Interfaces/IDelegationRequestForm";
import axios from "axios";
import { userContext } from "../../components/Context/UserContext";
import { InfoCircleFill } from "react-bootstrap-icons";
import { useOktaAuth } from "@okta/okta-react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import Spinner from "react-bootstrap/Spinner";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  postDataAsync,
  getDesignees,
  getManagerAdmin,
  teamsMessage,
  getDataAsync,
} from "../UserForm/Common";
import { IEmployee } from "../../Interfaces/IEmployee";
import { CAP_URL, HELP_DESK_URL } from "../../utils/constants";

const AdminDelegationForm = () => {
  let { id } = useParams<{ id: string }>();
  const user = useContext(userContext);
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  let today = new Date();

  const [maxDate, setMaxDate] = useState(
    today.addDays(60).toISOString().slice(0, 10)
  );
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState<any>();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailToast, setShowFailToast] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [manager, setManager] = useState({});

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm<IDelegationRequestForm>({
    mode: "onChange",
  });

  const [designators, setDesignators] = useState([]);

  const [designatorData, setDesignatorData] = useState(designators.slice());

  const [designees, setDesignees] = useState([]);

  const [designeeData, setDesigneeData] = useState(designees.slice());

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      getDesignees(accessToken).then((result) => {
        setDesignees(result);
        setDesigneeData(result);
        setDesignators(result);
        setDesignatorData(result);
      });
    };
    fetchData();
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    resetAsyncForm();
  }, [reset]);

  const resetForm = async (result) => {
    var loaded = result;
    loaded.startDate = result.startDate.toString().substr(0, 10);
    if (loaded.expiryDate) {
      loaded.expiryDate = result.expiryDate.toString().substr(0, 10);
    }
    setValue("designator", result.designator);
    reset(loaded);
  };

  const resetAsyncForm = useCallback(async () => {
    const accessToken = await oktaAuth.getAccessToken();
    await getDataAsync(id, accessToken).then((result: any) => {
      console.log(result);

      if (result.id === 0) {
        result.expiryDate = null;
      }
      resetForm(result);
      setIsLoaded(true);
    });
  }, [reset]);

  const filterDesignatorData = (filter: FilterDescriptor) => {
    const designatorData = designators.slice();
    return filterBy(designatorData, filter);
  };

  const filterDesignatorChange = (event: ComboBoxFilterChangeEvent) => {
    setDesignatorData(filterDesignatorData(event.filter));
  };

  const filterDesigneeData = (filter: FilterDescriptor) => {
    const designeeData = designees.slice();
    return filterBy(designeeData, filter);
  };
  const filterDesigneeChange = (event: ComboBoxFilterChangeEvent) => {
    setDesigneeData(filterDesigneeData(event.filter));
  };

  const handleOnChangedesignee = (event: ComboBoxChangeEvent) => {
    if (event.value) {
      setValue("designee", event.value);
    }
  };
  const handleOnChangedesignator = async (event: ComboBoxChangeEvent) => {
    const accessToken = await oktaAuth.getAccessToken();
    if (event.value) {
      setValue("designator", event.value);
      getManagerAdmin(event.value, accessToken).then((result) =>
        setManager(result)
      );
    }
  };
  const { onChangedesignee = handleOnChangedesignee, designee } = register(
    "designee",
    {
      required: true,
    }
  );
  const { onChangedesignator = handleOnChangedesignator, designator } =
    register("designator", {
      required: true,
    });

  const onStartDateChange = (e) => {
    console.log(e.target.value);
    let startDate = new Date(e.target.value);
    let max = startDate.addDays(60).toISOString().slice(0, 10);
    setMaxDate(max);
  };

  const handleResponse = async (post) => {
    if (post.status === 204 || 201) {
      setValue("id", post.data.id);
      uploadFile(post, file);
      post.data.status === "Active" && teamsMessage(post.data, manager);

      setShowSuccessToast(true);
      setTimeout(() => {
        if (post.data.status === "Active") {
          history.push("/Details/" + post.data.id);
        }
      }, 1000);
    }
  };

  const handleShow = () => setShowDelete(true);

  const handleClose = () => {
    setShowDelete(false);
  };

  const handleConfirmNo = () => {
    setShowDelete(false);
  };

  const handleError = async (error) => {
    console.log(error);
    setShowFailToast(true);
    setError(error);
  };

  const uploadFile = async (post, file) => {
    const accessToken = await oktaAuth.getAccessToken();
    if (file) {
      let i = 0;
      const fileUrl =
        process.env.REACT_APP_ENDPOINT_URL + `/api/FileStorage/InsertFile`;

      var formData = new FormData();
      for (i = 0; i < file.length; i++) {
        formData.append("asset", file[i]);
      }

      formData.append("Id", post.data.id);
      axios
        .post(fileUrl, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(function (post) {
          console.log(post);
        })
        .catch(function (error) {
          console.log(error);
          setError(error);
        });
    }
  };

  const saveFile = (e) => {
    let i = 0;
    let fileNames = [];
    console.log(e.target.files);
    setFile(e.target.files);
    setFileName(e.target.files.name);
    for (i = 0; i < e.target.files.length; i++) {
      fileNames.push(e.target.files[i].name);
      console.log(e.target.files[i].name);
    }
    setValue("fileName", fileNames);
  };

  const onSubmit: SubmitHandler<IDelegationRequestForm> = async (
    data,
    event
  ) => {
    const accessToken = await oktaAuth.getAccessToken();
    setShowDelete(false);
    setValue("status", event.target.value);
    data.status = event.target.value;
    data.designator = {
      name: data.designator.name,
      employeeId: data.designator.employeeID,
      email: data.designator.email,
    };
    data.submittedBy = {
      name: user.name,
      employeeId: user.bamboohrId,
      email: user.email,
    };

    if (file) {
      const fileUrl =
        process.env.REACT_APP_ENDPOINT_URL + `/api/FileStorage/InsertFile`;
      var formData = new FormData();
      formData.append("asset", file);

      axios
        .post(fileUrl, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(function (post) {
          console.log(post);
        })
        .catch(function (error) {
          console.log(error);
          setError(error);
        });
    }

    postDataAsync(data, accessToken)
      .then((post) => handleResponse(post))
      .catch((error) => handleError(error));
  };

  const onError: SubmitHandler<IDelegationRequestForm> = (data, event) => {
    const accessToken = oktaAuth.getAccessToken();
    postDataAsync(data, accessToken)
      .then((post) => handleResponse(post))
      .catch((error) => handleError(error));
  };

  const onPreview: SubmitHandler<IDelegationRequestForm> = (data, event) => {
    data.designator = { name: user.name, id: user.bamboohrId };
    data.submittedBy = { name: user.name, id: user.bamboohrId };
    alert(JSON.stringify(data));
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Not to exceed 60 days
    </Tooltip>
  );

  return (
    <>
      {isLoaded ? (
        <div className="container">
          <p className="text-center fw-600">
            Fill out the form below to delegate authorities and responsibilities
            to the assigned designee.
          </p>
          <p className="text-center fw-600">
            <i>
              Note: Review the Delegation of Authority document to familiarize
              yourself with the policy. The procedure defines the levels of
              authority for individuals within North Wind Group who may commit
              these companies in certain financial and business-related
              functions.
            </i>
          </p>

          <form className="pb-1" onSubmit={handleSubmit(onSubmit)}>
            <Form.Control type="hidden" {...register("id")} />

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Status:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  disabled
                  style={{ backgroundColor: "inherit" }}
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  value={getValues("status")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Designee:<span className="text-danger">*</span>
              </Form.Label>
              <Col sm="4">
                <ComboBox
                  ref={designee}
                  defaultValue={getValues("designee")}
                  onChange={onChangedesignee}
                  data={designeeData}
                  textField="name"
                  dataItemKey="id"
                  filterable={true}
                  onFilterChange={filterDesigneeChange}
                  name="designee"
                />
                <Form.Label>
                  {errors.designee && <span>This field is required</span>}
                </Form.Label>
              </Col>
              <Form.Label column sm="2">
                Designator:<span className="text-danger">*</span>
              </Form.Label>
              <Col sm="4">
                <ComboBox
                  ref={designator}
                  defaultValue={getValues("designator")}
                  onChange={onChangedesignator}
                  data={designatorData}
                  textField="name"
                  dataItemKey="id"
                  filterable={true}
                  onFilterChange={filterDesignatorChange}
                  name="designator"
                />
                <Form.Label>
                  {errors.designator && <span>This field is required</span>}
                </Form.Label>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Start Date:<span className="text-danger">*</span>
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="date"
                  {...register("startDate", { required: true })}
                  onChange={onStartDateChange}
                />
                <Form.Label>
                  {errors.startDate && <span>This field is required</span>}
                </Form.Label>
              </Col>
              <Form.Label column sm="2">
                End Date:<span className="text-danger">*</span>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <InfoCircleFill
                    width="24px"
                    height="24px"
                    className="ps-1"
                    fill="prussian-blue"
                  />
                </OverlayTrigger>
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  min={getValues("startDate")}
                  max={maxDate}
                  type="date"
                  {...register("expiryDate", { required: true })}
                />
                <Form.Label>
                  {errors.expiryDate && <span>This field is required</span>}
                </Form.Label>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Delegated Authorities:<span className="text-danger">*</span>{" "}
                <span>
                  <a
                    target="_blank"
                    className="text-prussian-blue"
                    href={CAP_URL}
                    rel="noreferrer"
                  >
                    Review policy CAP-7011
                  </a>
                </span>
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  placeholder="Example: 
              Go/No-Go Decisions
              Non-Disclosure Agreements
              Direct Purchases"
                  type="textarea"
                  as="textarea"
                  rows={4}
                  {...register("functions", { required: true })}
                />
                <Form.Label>
                  {errors.designee && <span>This field is required</span>}
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="12">
                <p>Applications:</p>
                <p>
                  Select the application(s) below to delegate to the designee.
                  The designee will inherit the same permissions to the
                  application(s) that the designator has, but if they do not
                  have access to these applications they will need to submit a
                  &nbsp;
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="text-prussian-blue"
                    href={HELP_DESK_URL}
                  >
                    help desk ticket.
                  </a>
                </p>
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Check
                  className="form-check form-check-inline"
                  label="Purchase Requisition"
                  value="PR"
                  inline={true}
                  id="PR"
                  name="application"
                  {...register("applications")}
                />
                <Form.Check
                  className="form-check form-check-inline"
                  label="Purchase Order"
                  value="PO"
                  inline={true}
                  id="PO"
                  name="application"
                  {...register("applications")}
                />
                <Form.Check
                  className="form-check form-check-inline"
                  label="Invoice"
                  value="Invoice"
                  inline={true}
                  id="INVOICE"
                  name="application"
                  {...register("applications")}
                />
                <Form.Check
                  className="form-check form-check-inline"
                  label="Personnel Requisition Request"
                  value="PRR"
                  inline={true}
                  id="PRR"
                  name="application"
                  {...register("applications")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                Comments:
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  type="textarea"
                  as="textarea"
                  {...register("comments")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                Attachment:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  name="file"
                  type="file"
                  placeholder=""
                  onChange={saveFile}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Stack direction="horizontal">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck4"
                    {...register("designatorAccepted", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="gridCheck4">
                    I've read and agree to the Delegation of Authority
                    Policy&nbsp;
                    <a
                      target="_blank"
                      className="text-prussian-blue"
                      href={CAP_URL}
                      rel="noreferrer"
                    >
                      CAP-7011
                    </a>
                    .
                  </label>
                </div>
              </Stack>
              <Form.Label>
                {errors.designatorAccepted && (
                  <span>This field is required</span>
                )}
              </Form.Label>
            </Form.Group>
            {showSuccessToast && (
              <div className="alert alert-success" role="alert">
                Successfully Submitted
              </div>
            )}
            {showFailToast && (
              <div className="alert alert-danger" role="alert">
                Submission Failed
              </div>
            )}
            <Stack direction="horizontal" gap={3} className="mb-2">
              <Link
                to="/Admin"
                className="btn btn-secondary me-auto"
                type="button"
              >
                Return
              </Link>

              {(getValues("status") === "New" ||
                getValues("status") === "Draft") && (
                <>
                  {(!process.env.NODE_ENV ||
                    process.env.NODE_ENV === "development") && (
                    <>
                      <button
                        className="btn btn-info"
                        type="button"
                        onClick={handleSubmit(onPreview)}
                        value="Preview"
                      >
                        Preview
                      </button>
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={handleSubmit(onError)}
                        value="Preview"
                      >
                        Force Error
                      </button>
                    </>
                  )}
                  {getValues("status") === "Draft" && (
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={handleShow}
                      value="Deleted"
                    >
                      Delete
                    </button>
                  )}
                  <button
                    className="btn btn-prussian-blue"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                    value="Draft"
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-prussian-blue"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                    value="Active"
                  >
                    Submit
                  </button>
                </>
              )}
            </Stack>
            <Modal show={showDelete} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Delegation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="bg-skyblue-80-tint p-3 fw-bold">
                  Are you sure you want to delete this delegation?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Col sm="5">
                  <Button
                    className="w-100"
                    variant="matterhorn"
                    onClick={handleConfirmNo}
                  >
                    No
                  </Button>
                </Col>
                <Col sm="5" className="mx-4">
                  <Button
                    className="w-100"
                    variant="prussian-blue"
                    value="Deleted"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Yes
                  </Button>
                </Col>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      ) : (
        <div className="d-flex justify-content-center vh-100">
          <div className="align-self-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
    </>
  );
};
export default AdminDelegationForm;
