import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import "../../custom.css";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "./../Footer/Footer";
import axios from "axios";
import { IDelegation } from "../../Interfaces/IDelegation";
import DOAIcon from "../../assets/DOA Icon.svg";
import {
    State,
    process,
    DataResult,
} from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { useOktaAuth } from "@okta/okta-react";
import { ColumnMenu } from "../FilterMenu/columnMenu";
import { CAP_URL } from "../../utils/constants";


//Grid render
const cellWithBadge = (props: GridCellProps) => {
    let badgeStyle = "";
    let expiryDate = new Date(props.dataItem.expiryDate); 
    let todaysDate = new Date();

    if (todaysDate > expiryDate) {
        props.dataItem.status = "Expired"; 
    } 
    if (props.dataItem.status == "Draft") {
        badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-bright-turquoise fs-6';
    } 
    if (props.dataItem.status == "Revoked") {
        badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-pale-sky fs-6';
    }
    if (props.dataItem.status == "Active") {
        badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-cerulean fs-6';
    } 
    if (props.dataItem.status == "Deleted") {
        badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-danger fs-6';
    } 
    if (props.dataItem.status == "Expired") {
        badgeStyle = 'container fluid badge badge-pill py-3 rounded-5 bg-warning fs-6';
    }


    return (
        <td className="d-flex justify-content-center ">
            <span className={badgeStyle}>{props.dataItem.status}</span>

        </td>
    );
};
//


const Admin = () => {

    const { oktaAuth } = useOktaAuth();
    const history = useHistory();
    const [table1, setTable1] = useState([]);
    
    const onRowClicked = (e: GridRowClickEvent) => {
        history.push("/DelegationRequestFormAdmin/" + e.dataItem.id);
        if (e.dataItem.status.toLowerCase() === 'draft') {
            history.push("/DelegationRequestFormAdmin/" + e.dataItem.id);
        }
        else {
            history.push("/Details/" + e.dataItem.id); 
        }
    };

    const _export = React.useRef<ExcelExport | null>(null);

    const excelExport = () => {
        if(_export.current !== null){
          _export.current.save();
        }
    }



// Filter
    const createDataState = (dataState: State) => {
        return {
            result: process(table1, dataState),
            dataState: dataState,
          }
    };
    
      let initialState = createDataState({
        take: 10,
        skip: 0,
      });

      const [result, setResult] = React.useState<DataResult>(initialState.result);
      const [dataState, setDataState] = React.useState<State>(initialState.dataState);
      const [length, setLength] = useState();

      const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
        setLength(updatedState.result.length);
      };

      const startDateRender = ({dataItem}: GridCellProps) => {
        let startDate: Date = new Date(dataItem.startDate).toLocaleString().split(',')[0] 
        return (

            <td>{startDate}</td>
        )
      };

      const expiryDateRender = ({dataItem}: GridCellProps) => {
        let expiryDate: Date = new Date(dataItem.expiryDate).toLocaleString().split(',')[0]
        
        return (

            <td>{expiryDate}</td>
        )
      };

      const createdDateRender = ({dataItem}: GridCellProps) => {
        let createdDate: Date = new Date(dataItem.createdDate).toLocaleString().split(',')[0]
        
        return (

            <td>{createdDate}</td>
        )
      };

      //

    const getAllDelegations = useCallback(async () => {
        const formurl = process.env.REACT_APP_ENDPOINT_URL + `/api/Delegations`;
        const accessToken = await oktaAuth.getAccessToken();
        await axios.get(formurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((result: any) => {
            var loaded = result.data;
            result.data.forEach((element: IDelegation, index) => {
                let array = [element.applications, element.functions]
                loaded.at(index).functions = array.join(' ');
            })
            setTable1(loaded);
            setResult(loaded.slice(0, 10));
            setLength(loaded.length);
            
        })

    }, []);

    useEffect(() => {
        getAllDelegations();
    }, []);



    return (
        <>
            <div className="text-center">
                <p>
                    To review the Delegation of Authority policy, click here: &nbsp;
                    <a className="text-prussian-blue" target="_blank" href={CAP_URL}>
                        CAP-7011 in the Document Library
                    </a>
                    .
                </p>
                <div className="py-3">
                <Link to="DelegationRequestFormAdmin/0">
                    <img
                        src={DOAIcon}
                        alt="Delegation of Authority Icon"
                        style={{maxWidth: "100px", minWidth: "50px"}}
                    />
                </Link>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="DelegationRequestFormAdmin/0" className="createNewText">Create New</Link>
                </div>
            </div>
            <div className="py-5">
                <Stack direction="horizontal" gap={3}>
                    <div className="ms-auto py-3">
                    </div>
                    <button className="btn btn-prussian-blue mb-2" type="submit" onClick={excelExport}>
                        Export
                    </button>
                </Stack>
                    {table1 &&
                    <ExcelExport
                        data={table1}
                        ref={_export}
                    >
                        { result &&
                    <Grid
                        data={ result}
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        pageable={true}
                        sortable={true}
                        total={length}
                        onRowClick={(dataItem) => onRowClicked(dataItem)}>
                        <GridColumn field="id" title="Delegation No." columnMenu={ColumnMenu} />
                        <GridColumn field="designator" title="Designator" columnMenu={ColumnMenu} />
                        <GridColumn field="designee" title="Designee" columnMenu={ColumnMenu} />
                        <GridColumn field="startDate" title="Start Date" columnMenu={ColumnMenu} cell={startDateRender} />
                        <GridColumn field="expiryDate" title="End Date"  columnMenu={ColumnMenu} cell={expiryDateRender} />
                        <GridColumn field="createdDate" title="Created Date" columnMenu={ColumnMenu} cell={createdDateRender} />
                        <GridColumn field="functions" title="Delegated Authorities" columnMenu={ColumnMenu} />
                        <GridColumn field="status" title="Status" cell={cellWithBadge} columnMenu={ColumnMenu} />
                        <GridColumn field="comments" title="Comments" columnMenu={ColumnMenu} />
                        <GridColumn field="revokeComments" title="Revoke Comments" columnMenu={ColumnMenu} />
                    </Grid>
}
                    </ExcelExport>
                    
                }
            </div>
            <Footer />
        </>
    );
};
export default Admin;
