import { IDelegationRequestForm } from "../../Interfaces/IDelegationRequestForm";
import { IDelegationRequestData } from "../../Interfaces/IDelegationRequestData";
import { IEmployee } from "../../Interfaces/IEmployee";
import { IUser } from "../../Interfaces/IUser";
import axios from "axios";




const addDays = (days) => {
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
}

const mapDataToForm = (data: IDelegationRequestData): IDelegationRequestForm => {
    const formModel: IDelegationRequestForm = {
        id: data.id,
        status: data.status,
        designator: data.designator,
        designee: data.designee,
        submittedBy: data.submittedBy,
        startDate: data.startDate === null ? new Date().toString().substr(0, 10) : data.startDate.toString().substr(0, 10),
        expiryDate: data.expiryDate === null ? new Date().toString().substr(0, 10) : data.expiryDate.toString().substr(0, 10),
        functions: data.functions ?? "",
        applications: data.applications ?? [],
        comments: data.comments ?? "",
        fileName: data.fileName ?? [],
        designatorAccepted: data.designatorAccepted,
        revokeComments: data.revokeComments ?? "",
        createdDate: data.createdDate === null ? new Date().toString().substr(0, 10) : data.createdDate.toString().substr(0, 10),
    }
    return formModel; 
}


const mapFormToData = (data: IDelegationRequestForm): IDelegationRequestData => {
    const dataModel: IDelegationRequestData = {
        id: data.id,
        status: data.status,
        designator: data.designator,
        designee: data.designee,
        submittedBy: data.submittedBy,
        startDate: new Date(data.startDate),
        expiryDate: new Date(data.expiryDate),
        functions: data.functions ?? null,
        applications: data.applications ?? null,
        comments: data.comments ?? null,
        fileName: data.fileName ?? null,
        designatorAccepted: data.designatorAccepted,
        revokeComments: data.revokeComments ?? null,
        createdDate: new Date(data.createdDate)
    }
    return dataModel;
}


const postDataAsync = async (data: IDelegationRequestData, token) => {

    return await axios
        .post(process.env.REACT_APP_ENDPOINT_URL + `/api/Forms`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });  
};


const getManager = async (user: IUser, token): Promise<IEmployee> => {
    return await axios
        .get( process.env.REACT_APP_ENDPOINT_URL + `/api/manager/${user.bamboohrId}` , {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(result => { return result.data })
        .catch(error => { return error } ); 
}

const getManagerAdmin = async (delegator, token): Promise<IEmployee> => {
    return await axios
        .get( process.env.REACT_APP_ENDPOINT_URL + `/api/manager/${delegator.employeeID}` , {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(result => { return result.data })
        .catch(error => { return error } ); 
}

const getDesignees = async (token): Promise<IEmployee[]>  => { 
    return await axios
        .get( process.env.REACT_APP_ENDPOINT_URL + `/api/employeelist` , {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(result => { return result.data })
        .catch(error => { return error });
}

const getDataAsync = async (id, token) : Promise<IDelegationRequestData> => {
    return await axios
        .get(process.env.REACT_APP_ENDPOINT_URL + `/api/Forms/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(result => { return result.data })
        .catch(error => { return error });
};
   

const mapLoggedInUserToEmployee = (user: IUser) => {
    const obj: IEmployee = {
        name: user.name,
        employeeId: user.bamboohrId,
        email: user.email
    }
    return obj;
}

const teamsMessage = (data, manager:{name:string, email:string}) :any => {
        if (data.status === "Active") {
          const teamsMessageurl =
            "https://chat-to-chat.azurewebsites.net:443/api/Send-Chats/triggers/manual/invoke?api-version=2022-05-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N1ef4HcngVg0pYR1EgZFGwT10peO6vQdf1Dg_e3RxZs";

          const teamsMessage = {
            delegation: {
              delegatorName: data.designator,
              delegatorEmail: data.designatorEmail,
              form: {
                designeeName: data.designee,
                designeeEmail: data.designeeEmail,
                formID: data.id,
                managerName: manager.name.split(",").reverse().join(" ").trim(),
                managerEmail: manager.email,
                submitedDate:
                  data.createdDate.substr(5, 5).trim() +
                  "-" +
                  new Date().getFullYear(),
                startDate:
                  data.startDate.substr(5, 5).trim() +
                  "-" +
                  new Date().getFullYear(),
                expiryDate:
                  data.expiryDate.substr(5, 5).trim() +
                  "-" +
                  new Date().getFullYear(),
                applications: data.applications,
                functions: data.functions,
                Link:
                  process.env.REACT_APP_ENDPOINT_URL + `/Details/${data.id}`,
              },
            },
          };

          axios
            .post(teamsMessageurl, teamsMessage);
            
        }
}

export { mapDataToForm, mapFormToData, mapLoggedInUserToEmployee, postDataAsync, getManager, getDesignees, getDataAsync, addDays, getManagerAdmin, teamsMessage }; 
