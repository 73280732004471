import * as React from "react";
import { useEffect, useState, createContext } from "react";
import { useOktaAuth } from "@okta/okta-react/";
import { IUser } from "../../Interfaces/IUser";
import axios from "axios";
import { set } from "react-hook-form";

export let userContext = createContext<IUser>(null);

const UserContext = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<IUser>(null);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      if (!authState || !authState.isAuthenticated) {
        setUserInfo(null);
      } else {
        await oktaAuth
          .getUser()
          .then((info: any) => {
            let adminCheck = getAdmin(info.bamboohrId);
            info.isAdmin = adminCheck;
            setUserInfo(info);

            setIsloading(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const getAdmin = async (id) => {
      const accessToken = await oktaAuth.getAccessToken();
      const formurl =
        process.env.REACT_APP_ENDPOINT_URL + `/api/Forms/isAdmin/${id}`;
      return await axios
        .get<Response>(formurl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((result) => {
          return result.data;
        });
    };

    setIsloading(true);
    getUser();
    userContext = createContext(userInfo);
    setIsloading(false);
  }, [authState, oktaAuth]);

  return (
    <>
      <userContext.Provider value={userInfo}>
        <>
          <>{children}</>
        </>
      </userContext.Provider>
    </>
  );
};
export default UserContext; 